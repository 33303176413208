@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

html * {
  font-family: 'Rubik', sans-serif !important;
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-sticky {
  position: sticky;
  top: 0;
  right: 0;
  /* width: 100%; */
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding: 17px 42px 17px 42px;
}

.react-pdf__Document {
  overflow: auto;
  height: 100%;
  width: 100%;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page {
  height: 100%;
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

/* @media (min-width:1px){ */
.react-pdf__Page__annotations,
.annotationLayer {
  display: none;
  height: 0px !important;
}

/* ------------------------------date-range-picker styles--------------------------- */
.date-range-wrapper .react-daterange-picker__wrapper {
  display: none;
}

.date-range-wrapper {
  width: 100%;
}

.date-range-wrapper .react-daterange-picker {
  width: 100%;
}

.date-range-wrapper .react-calendar {
  width: 100%;
  border: none;
}

.date-range-wrapper .react-daterange-picker__calendar {
  width: 100%;
}

.date-range-wrapper .react-daterange-picker {
  display: block;
}

.date-range-wrapper .react-daterange-picker__calendar--closed {
  display: block !important;
}

.date-range-wrapper .react-calendar__tile--now {
  aspect-ratio: 1 / 1;
  background-color: transparent !important;
}

.date-range-wrapper .react-calendar__month-view__days__day--weekend {
  color: #000000 !important;
}

.date-range-wrapper .react-calendar__month-view__weekdays {
  text-transform: none !important;
  text-decoration: none !important;
  font-weight: none !important;
  color: #64677A !important;
}

.date-range-wrapper .react-calendar__month-view__days__day--neighboringMonth {
  color: #BABDCC !important;
}

.date-range-wrapper .react-calendar__month-view__weekdays abbr {
  text-decoration: none !important;
  font-size: 16px;
  line-height: 24px;
  color: #64677A;
}

.date-range-wrapper .react-calendar__month-view__weekdays {
  /* margin-top: 10px; */
  border-top: 1px solid #EBECF2;
  border-bottom: 1px solid #EBECF2;
  padding: '10px 0px'
}

.date-range-wrapper .react-calendar__tile {
  font-size: 20px;
  line-height: 30px;
  /* border-radius: 4px; */
  aspect-ratio: 1 / 1;
  padding: auto;
  margin: 2px 0px;
}

.date-range-wrapper .react-calendar__tile:enabled:hover {
  background-color: transparent;
  aspect-ratio: 1 / 1;
  margin: 2px 0px;
  position: relative;
}
.date-range-wrapper .react-calendar__tile:enabled:hover abbr {
  position: relative;
  z-index:1;
  color: #FFFFFF;
}

.date-range-wrapper .react-calendar__tile:hover::before{
  position: absolute;
  top:0;
  left:0;
  background: #5F6F57 !important;
  border-radius: 50%;
  content:"";
  width: 100%;
  height: 100%;
  z-index:0;
}

.date-range-wrapper .react-calendar__navigation__next2-button {
  display: none;
}

.date-range-wrapper .react-calendar__navigation__prev2-button {
  display: none;
}

.date-range-wrapper .react-calendar__tile--active {
  background-color: #EAF4DF !important;
  color: #000000 !important;
  aspect-ratio: 1 / 1;
  border-radius: 0px;
  margin: 2px 0px;
  /* border-radius: 50%; */
}

.date-range-wrapper .react-calendar__navigation__prev-button {
  background-color: #f9f9f9 !important;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  right: 60px;
  font-size: 26px;
}

.date-range-wrapper .react-calendar__navigation {
  margin-bottom: 10px;
}

.date-range-wrapper .react-calendar__navigation button {
  min-Width: 32px !important;
}

.react-calendar__navigation__next-button {
  background-color: #f9f9f9 !important;
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  font-size: 26px;
}

.date-range-wrapper .react-calendar__navigation__label {
  display: none;
}

.react-calendar__month-view__days button:nth-child(7n) {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-calendar__month-view__days button:nth-child(7n+1) {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.date-range-wrapper .react-calendar__tile--rangeStart{
  background:#EAF4DF !important;
  position: relative;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.date-range-wrapper .react-calendar__tile--rangeStart::before{
  position: absolute;
  top:0;
  left:0;
  background: #5F6F57 !important;
  border-radius: 50%;
  content:"";
  width: 100%;
  height: 100%;
  z-index:0;
}
.date-range-wrapper .react-calendar__tile--rangeStart abbr{
  position: relative;
  z-index:1;
  color: #FFFFFF;
}

.date-range-wrapper .react-calendar__tile--rangeEnd{
  background:#EAF4DF !important;
  position: relative;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.date-range-wrapper .react-calendar__tile--rangeEnd::before{
  position: absolute;
  top:0;
  left:0;
  background: #5F6F57 !important;
  border-radius: 50%;
  content:"";
  width: 100%;
  height: 100%;
  z-index:0;
}

.date-range-wrapper .react-calendar__tile--rangeEnd abbr{
  position: relative;
  z-index:1;
  color: #FFFFFF;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #EAF4DF !important;
}

.react-calendar--selectRange .react-calendar__tile--hoverStart {
  background: #EAF4DF !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.react-calendar--selectRange .react-calendar__tile--hoverEnd {
  background: #EAF4DF !important;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}

.react-calendar--selectRange .react-calendar__tile--hoverBothEnds {
  background: none !important;
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.react-daterange-picker.disabled-date-range-picker .react-calendar__tile {
  pointer-events: none;
}

.react-daterange-picker.disabled-date-range-picker .react-calendar__viewContainer {
  margin-top: 54px;
}

.react-calendar__tile:disabled {
  background-color: #fff !important;
  color: #BABDCC !important;
}

.react-calendar__tile:disabled {
  background-color: #fff !important;
  color: #BABDCC !important;
}

.date-range-wrapper .react-calendar__tile:disabled:hover::before {
  background: none !important;
}
/* .date-range-wrapper .react-calendar__tile--active{
  background:#EAF4DF !important;
  position: relative;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
} */